.tile-container {
  position: absolute;
  width: 256px;
  height: auto;
  clip-path: polygon(50% -1%, 105% 50%, 50% 102%, -4% 49%);
  cursor: pointer;
  transition: filter 0.3s, transform 0.3s;
  z-index: 1;
}

.tile-container.ground {
  clip-path: polygon(50% -1%, 105% 50%, 50% 102%, -4% 49%);
}

.tile-container.base {
  clip-path: polygon(
    1% 58%,
    0% 64%,
    48% 100%,
    53% 100%,
    100% 64%,
    99% 58%,
    74% 39%,
    75% 21%,
    63% 12%,
    61% 8%,
    48% 0%,
    45% 0%,
    42% 7%,
    45% 14%,
    41% 15%,
    32% 22%,
    30% 21%,
    26% 23%,
    24% 32%,
    23% 35%,
    25% 39%
  );
}

.tile-container.selected {
  transform: scale(1.05);
  z-index: 1;
}

.tile-container.adjacent {
  filter: brightness(1.5);
}

.tile {
  width: 100%;
  height: auto;
  user-select: none;
  pointer-events: none;
}

.tile-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 2px 4px;
  font-size: 12px;
  pointer-events: none;
}

.unit {
  position: absolute;
  width: 50px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  z-index: 2;
  border: 2px solid #ffa5006b;
  border-radius: 50%;
}

.unit.mutants {
  border: 2px solid #afafaf6b;
}

.units-amount {
  position: absolute;
  top: 8%;
  left: 116.5px;
  z-index: 4;
  width: 24px;
  padding: 4px 0;
  text-align: center;
  background: #252424c4;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
  user-select: none;
  pointer-events: none;
}

.units-amount.no-stamina {
  border-bottom: 4px solid #b7b7b7;
}

.units-amount.one-stamina {
  border-bottom: 4px solid #00de18;
}

.units-amount.two-stamina {
  border-bottom: 4px solid #00de18;
  border-left: 4px solid #00de18;
}

.units-amount.three-stamina {
  border-bottom: 4px solid #00de18;
  border-left: 4px solid #00de18;
  border-top: 4px solid #00de18;
}

.units-amount.four-stamina {
  border: 4px solid #00de18;
}

.base .units-amount {
  top: 18%;
}

.effect {
  position: absolute;
  width: 100px;
  height: auto;
  top: 40%;
  left: 55%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  user-select: none;
  z-index: 3;
}

.tile-control-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  clip-path: inherit;
  pointer-events: none;
}

.tooltip {
  z-index: 12;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  pointer-events: none;
  z-index: 10;
  white-space: pre-line;
  text-align: center;
  font-size: 12px;
}