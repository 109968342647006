body {
  background-color: #48535e;
  background: url("../../public/ui/space.png");
  background-repeat: repeat;
}

body::-webkit-scrollbar {
  display: none;
}

.grid-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.isometric-grid {
  position: relative;
  min-width: 1040px;
  translate: 50%;
}
