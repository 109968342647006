.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.build-modal {
  background-color: #23272f;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  color: #fff;
  position: relative;
}

.modal-title {
  text-align: center;
  margin-bottom: 20px;
}

.buildings-container {
  display: flex;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.building-card {
  width: 30%;
  background-color: #2c313a;
  padding: 10px;
  margin: 10px;
  border-radius: 8px;
  text-align: center;
}

.building-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
  max-width: 126px;
  height: 92px;
}

.building-title {
  margin-top: 10px;
  font-size: 18px;
  margin-bottom: 0;
}

.building-description {
  font-size: 14px;
  margin: 10px 0;
}

.build-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.build-button:hover {
  background-color: #0069d9;
}

.build-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.credits-icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-left: 4px;
}

.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
}

.close-modal-button:hover {
  color: #ccc;
}
