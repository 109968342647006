.production-panel, .tile-info-container {
  position: fixed;
  bottom: 165px;
  right: 20px;
  color: #fff;
  padding: 10px;
  z-index: 10;
  width: 220px;
  background-color: #23272f;
  border-radius: 10px;
  box-shadow: 0 1px 1px #00000013, 0 2px 2px #00000013, 0 4px 4px #00000013, 0 8px 8px #00000013, 0 16px 16px #00000013;
  border: 3px solid #ffffff1a;
  user-select: none;
}

.production-title {
  text-align: left;
  border-bottom: 1px solid #ffffff40;
  padding-bottom: 5px;
  font-weight: bold;
  margin-bottom: 10px;
}

.production-hint {
  text-align: center;
  font-size: 13px;
  background: #ffffff1c;
  padding: 5px 0;
}

.tile-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tile-stats {
  display: flex;
}

.tile-info-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: bold;
  font-size: 16px;
  user-select: none;
}

.tile-info-item span {
  margin-left: 5px;
}

.tile-info-item img,
.tile-info-item svg {
  vertical-align: middle;
}

.unit-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}

.unit-icon {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  cursor: pointer;
  border: 1px solid #fff;
  border-radius: 50%;
}

.unit-icon.disabled {
  filter: opacity(0.4);
  pointer-events: none;
}

.unit-icon.selected {
  border: 2px solid #00ff00;
  border-radius: 4px;
}

.quantity-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.quantity-selector input[type="range"] {
  width: 100%;
}

.quantity-selector input[type="number"] {
  width: 60px;
  text-align: center;
}

.produce-button {
  width: 100%;
  padding: 8px;
  background-color: #28a745;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.produce-button:hover {
  background-color: #218838;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 5px;
  background: none;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.tile-info-container {
  bottom: 100px;
}

.upgrade-section {
  margin-bottom: 0;
}

.upgrade-button {
  flex-grow: 1;
  text-align: center;
  width: 100%;
  padding: 8px;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.upgrade-cost {
  display: inline-flex;
  align-items: center;
}

.upgrade-cost img {
  margin-left: 4px;
}

.upgrade-button:hover {
  background-color: #0069d9;
}

.upgrade-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}
